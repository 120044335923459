/**
 * Use stacked large article row when there is a need to promote 2-4 stories or promotions in one row when you want image stacked on top of content, and you also want to utilize the full width of the grid.
 *
 * @module views/components/ArticleRowStackedLarge
 * @memberof -Common
 */
import './ArticleRowStackedLarge.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import SwiperCore, {
  Keyboard,
  Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import palette_variables from 'web-styles/src/_exports.module.scss';

import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import { constants } from '@ulta/utils/constants/constants';

import Article from '../Article/Article';
import { upCount } from '../ArticleRowStackedCompact/ArticleRowStackedCompact';

SwiperCore.use( [Navigation, Keyboard] );

let swiperCounter = 1;

/**
 * Represents a ArticleRowStackedLarge component
 *
 * @method
 * @param {ArticleRowStackedLargeProps} props - React properties passed from composition
 * @returns ArticleRowStackedLarge
 */
export const ArticleRowStackedLarge = function( props ){
  const {
    backgroundColor,
    patternBackground,
    title,
    eyebrow,
    subtitle,
    articles,
    articleTextAlign,
    inset,
    rootMargin,
    root,
    threshold,
    carousel,
    carouselAccessibility } = props ;
  const carouselRef = useRef( null );
  const [swiper, setSwiper] = useState( '' );
  const upCountText = upCount( articles?.length );
  const { breakpoint } = useDeviceInflection();
  const isMobile = breakpoint && ( breakpoint.CURRENT_BREAKPOINT === 'SM' );
  const carouselMobile = isMobile && props.carousel && props.articles?.length > 2;

  swiperCounter += 1;

  useIntersectionObserver( carouselRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  if( !articles || !articleTextAlign ){
    return null;
  }

  return (
    <div className={
      classNames( 'ArticleRowStackedLarge', {
        'ArticleRowStackedLarge__withBackgroundColor': backgroundColor && !patternBackground,
        [`ArticleRowStackedLarge__numArticle--0${articles?.length}`]:articles
      } ) }
    ref={ carouselRef }
    >
      <div className={
        classNames( 'ArticleRowStackedLarge__layout', {
          ['ArticleRowStackedLarge__layout--inset']:inset,
          ['ArticleRowStackedLarge__layout--standard']:!carousel
        } )
      }
      >
        { patternBackground &&
          <div className='ArticleRowStackedLarge__patternBackground'>
            <UltaAsset
              { ...( patternBackground ) }
            />
          </div>
        }
        <GridContainer
          { ...( !patternBackground && { fullBleedContentBackgroundColor: backgroundColor } ) }
        >
          <div className={
            classNames( 'ArticleRowStackedLarge__mainContainer', {
              ['ArticleRowStackedLarge__mainContainer--carousel']:carousel,
              ['ArticleRowStackedLarge__mainContainer--standard']:!carousel
            } )
          }
          >
            { eyebrow &&
              <div className={ classNames( 'ArticleRowStackedLarge__headingEyebrow', {
                'ArticleRowStackedLarge__headingEyebrow--withPatternBgPosition': patternBackground
              } )
              }
              >
                <Text
                  htmlTag='p'
                  textStyle='eyebrow'
                  textAlign='center'
                >
                  { eyebrow }
                </Text>
              </div>
            }
            { title?.text &&
              <div className={ classNames( 'ArticleRowStackedLarge__headingTitle', {
                'ArticleRowStackedLarge__headingTitle--withPatternBgPosition': patternBackground && !eyebrow
              } )
              }
              >
                <Text
                  htmlTag={ title.htmlTag }
                  textStyle='title-4'
                  textAlign='center'
                >
                  { title.text }
                </Text>
              </div>
            }
            { subtitle &&
              <div className='ArticleRowStackedLarge__headingSubtitle'>
                <Text
                  htmlTag='p'
                  textStyle='subtitle-1'
                  textAlign='center'
                >
                  { subtitle }
                </Text>
              </div>
            }
            <div className='ArticleRowStackedLarge__articleContainer'>
              { articles && carouselMobile &&
                <div className='ArticleRowStackedLarge__carousel'
                  role='region'
                  aria-label={ carouselAccessibility }
                >
                  <Swiper
                    onSwiper={ ( instance ) => setSwiper( instance ) }
                    watchOverflow={ true }
                    watchSlidesProgress={ true }
                    watchSlidesVisibility={ true }
                    freeMode={ {
                      minimumVelocity: 0.01,
                      momentum: true,
                      momentumBounce: true,
                      momentumBounceRatio: 1.2,
                      momentumRatio: 1.2,
                      momentumVelocityRatio: 1.2,
                      sticky: true
                    } }
                    speed={ 600 }
                    preloadImages={ true }
                    spaceBetween={ 16 }
                    breakpoints={ {
                      [palette_variables.breakpointSM]: {
                        slidesPerView: 1.6,
                        slidesPerGroup: 2
                      }
                    } }
                  >
                    { articles?.length > 0 && articles.map( ( content, index ) => (
                      <SwiperSlide key={ index }>
                        <div
                          aria-label={ `Article ${index + 1} of ${articles.length}` }
                        >
                          <Article
                            { ...content }
                            cardOrientation='vertical'
                            articleTextAlign={ articleTextAlign }
                            backgroundColor={ backgroundColor }
                            upCountText={ upCountText }
                            upCountCounter={ index }
                            key={ index }
                          />
                        </div>
                      </SwiperSlide>
                    ) )
                    }
                  </Swiper>
                </div>
              }
              { articles && !carouselMobile &&
                articles.map( ( content, index ) => (
                  <div className='ArticleRowStackedLarge__contentContainer'
                    key={ index }
                  >
                    <Article
                      { ...content }
                      cardOrientation='vertical'
                      articleTextAlign={ articleTextAlign }
                      backgroundColor={ backgroundColor }
                      upCountText={ upCountText }
                      upCountCounter={ index }
                    />
                  </div>
                ) )
              }
            </div>
          </div>
        </GridContainer>
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef ArticleRowStackedLargeProps
 * @type {object}
 * @property {object} title - Sets the title properties
 * @property {string} eyebrow - Sets the eyebrow
 * @property {string} subtitle - Sets the subtitle
 * @property {cms-checkbox} carousel - Boolean value for carousel
 * @property {string} backgroundColor - Sets the backgroundColor
 * @property {string} patternBackground - Sets the patternBackground
 * @property {object} articles - Sets the article properties
 * @property {string} articleTextAlign - Sets the article text alignment
 * @property {object} linkAction - Sets the linkAction
 */

export const propTypes = {
  title: PropTypes.object,
  eyebrow: PropTypes.string,
  subtitle: PropTypes.string,
  carousel: PropTypes.bool,
  inset: PropTypes.bool,
  backgroundColor: PropTypes.string,
  patternBackground:  PropTypes.object,
  articles: PropTypes.array,
  articleTextAlign: PropTypes.string,
  linkAction: PropTypes.object
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} carouselDetailsAccessibility='This is a carousel of items with next and previous button to move through the slides' - The default value for carousel is aria-label
 * @property {string} carouselAccessibility='carousel' - The default value for aria-label is set to text 'carousel'.
 */

export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS,
  carouselAccessibility :'carousel of articles'
};

ArticleRowStackedLarge.propTypes = propTypes;
ArticleRowStackedLarge.defaultProps = defaultProps;

export default ArticleRowStackedLarge;