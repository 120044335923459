/**
 * Use stacked compact article row when there is a need to promote 3-7 bite size stories or promotions of lesser importance, and when you want to use illustrations and/or iconography as the image.
 *
 * @module views/components/ArticleRowStackedCompact
 * @memberof -Common
 */
import './ArticleRowStackedCompact.scss';

import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import { constants } from '@ulta/utils/constants/constants';

import Article from '../Article/Article';

/**
 * UpCount to get the number of articles being displayed
 *
 * @method
 * @param {object} data - All of the article data passed through
 * @returns The article
 */

export const upCount = ( length ) => {
  switch ( length ){
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    case 6:
      return 'six';
    case 7:
      return 'seven';
    case 8:
      return 'eight';
  }
};

/**
 * Represents a ArticleRowStackedCompact component
 *
 * @method
 * @param {ArticleRowStackedCompactProps} props - React properties passed from composition
 * @returns ArticleRowStackedCompact
 */
export const ArticleRowStackedCompact = function( props ){
  const {
    backgroundColor,
    patternBackground,
    articles,
    articleTextAlign,
    title,
    eyebrow,
    subtitle,
    root,
    rootMargin,
    threshold
  } = props;
  const upCountText = upCount( articles?.length );
  const articleRowStackedCompactRef = useRef( null );
  useIntersectionObserver( articleRowStackedCompactRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  if( !articles || !articleTextAlign ){
    return null;
  }

  return (
    <div className={
      classNames( 'ArticleRowStackedCompact', {
        'ArticleRowStackedCompact__withBackgroundColor': backgroundColor && !patternBackground,
        [`ArticleRowStackedCompact__numArticle--0${articles?.length}`]:articles
      } )
    }
    ref={ articleRowStackedCompactRef }
    >
      {
        patternBackground &&
          <div className='ArticleRowStackedCompact__patternBackground'>
            <UltaAsset
              { ...( patternBackground ) }
            />
          </div>
      }
      <GridContainer
        { ...( !patternBackground && { fullBleedContentBackgroundColor: backgroundColor } ) }
      >
        { eyebrow &&
          <div className={ classNames( 'ArticleRowStackedCompact__headingEyebrow', {
            'ArticleRowStackedCompact__headingEyebrow--withPatternBgPosition': patternBackground
          } )
          }
          >
            <Text
              htmlTag='p'
              textStyle='eyebrow'
              textAlign='center'
            >
              { eyebrow }
            </Text>
          </div>
        }
        { title?.text &&
          <div className={ classNames( 'ArticleRowStackedCompact__headingTitle', {
            'ArticleRowStackedCompact__headingTitle--withPatternBgPosition': patternBackground && !eyebrow
          } )
          }
          >
            <Text
              htmlTag={ title.htmlTag }
              textStyle='title-4'
              textAlign='center'
            >
              { title.text }
            </Text>
          </div>
        }
        { subtitle &&
          <div className='ArticleRowStackedCompact__headingSubtitle'>
            <Text
              htmlTag='p'
              textStyle='subtitle-1'
              textAlign='center'
            >
              { subtitle }
            </Text>
          </div>
        }
        { articles &&
          articles.map( ( content, index ) => (
            <Article
              { ...content }
              cardOrientation='vertical'
              articleTextAlign={ articleTextAlign }
              backgroundColor={ backgroundColor }
              upCountText={ upCountText }
              upCountCounter={ index }
              key={ index }
            />
          ) )
        }
      </GridContainer>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef ArticleRowStackedCompactProps
 * @type {object}
 * @property {object} title - Sets the title properties
 * @property {string} eyebrow - Sets the eyebrow
 * @property {string} subtitle - Sets the subtitle
 * @property {string} backgroundColor - Sets the backgroundColor
 * @property {string} patternBackground - Sets the patternBackground
 * @property {object} articles - Sets the article properties
 * @property {string} articleTextAlign - Sets the article text alignment
 * @property {object} linkAction - Sets the linkAction
 */

export const propTypes = {
  title: PropTypes.object,
  eyebrow: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  patternBackground:  PropTypes.object,
  articles: PropTypes.array,
  articleTextAlign: PropTypes.string,
  linkAction: PropTypes.object
};

/**
* Default values for passed properties
* @type object
*/
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

ArticleRowStackedCompact.defaultProps = defaultProps;
ArticleRowStackedCompact.propTypes = propTypes;

export default ArticleRowStackedCompact;