/**
 * Use regular article cards for content that warrants mid-level prominence within a set of featured content, examples being a featured deal or marketing content. Arrange in various ways to create sections of content on a page.
 *
 * @module views/components/Article
 * @memberof -Common
 */
import './Article.scss';

import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';

import Tag from '@ulta/components/Tag/Tag';

import { constants } from '@ulta/utils/constants/constants';

/**
 * Represents a Article component
 *
 * @method
 * @param {ArticleProps} props - React properties passed from composition
 * @returns Article
 */

export const Article = function( props ){
  const {
    image,
    upCountText,
    upCountCounter,
    cardOrientation,
    backgroundColor,
    cardColor,
    highlight,
    tag,
    tagDisplayType,
    eyebrow,
    disclaimer,
    valueTag,
    articleTextAlign,
    textLayout,
    title,
    titleSupport,
    subtitle,
    linkAction,
    root,
    rootMargin,
    threshold
  } = props;
  const articleNoLinkStyle = !linkAction?.url && !linkAction?.label;
  const articleHiddenLink = linkAction && !linkAction.label;
  const articleLink = linkAction?.url;
  const articleCardRef = useRef( null );
  const isGrayBackground = ( backgroundColor === 'neutral-25' );
  const hasColor = ( ( backgroundColor !== null ) || ( cardColor !== undefined ) );
  const hasBackgroundColor = backgroundColor && !isGrayBackground;
  const hasColorNoGray = ( ( hasBackgroundColor && hasColor ) || ( cardColor !== undefined ) );
  useIntersectionObserver( articleCardRef, {
    root: root,
    rootMargin: rootMargin,
    threshold: threshold
  }, handleIntersection( props ) );

  /**
   * Represents a articleContent function
   * This function will take in the data from user and format it for the Article
   * @method
   * @returns articleContent
   */

  const articleContent = () => {
    return (
      <>
        <div className='Article__contentImage'>
          { image && (
            <UltaAsset
              imageUrl={ image.imageUrl }
              videoUrl={ image.videoUrl }
              altText={ image.altText }
              { ...( image.metaData && { metaData: image.metaData } ) }
              shouldUseIntersectionObserver={ false }
            />
          ) }
          { highlight &&
          <div className='Article__highlight'>
            <Text
              textStyle='body-3'
            >
              { highlight }
            </Text>
          </div>
          }
        </div>
        <div className={
          classNames( 'Article__contentDetails', {
            [`Article__contentDetails--${textLayout}`]:textLayout
          } )
        }
        >
          { tag &&
          <div className={
            classNames( 'Article__contentTag', {
              [`Article__contentTag--${articleTextAlign}`]:articleTextAlign
            } )
          }
          >
            <Tag
              messageText={ tag }
              messageType={ hasColorNoGray ? 'white' : tagDisplayType }
            />
          </div>
          }
          { eyebrow &&
          <div className='Article__contentEyebrow'>
            <Text
              htmlTag='p'
              textStyle='eyebrow'
              textAlign={ articleTextAlign }
            >
              { eyebrow }
            </Text>
          </div>
          }
          { title &&
          <div className={
            classNames( 'Article__contentTitle', {
              'Article__contentTitle--hiddenLink': articleHiddenLink,
              [`Article__contentTitle--${ articleTextAlign }`]: articleHiddenLink
            } )
          }
          >
            <Text
              htmlTag='h3'
              textStyle='title-6'
              textAlign={ articleTextAlign }
            >
              { title }
            </Text>
          </div>
          }
          { subtitle &&
          <div className='Article__contentSubtitle'>
            <Text
              htmlTag='p'
              textStyle='body-2'
              color='neutral-800'
              textAlign={ articleTextAlign }
            >
              { subtitle }
            </Text>
          </div>
          }
          { titleSupport &&
          <div className='Article__contentTitlesupport'>
            <Text
              htmlTag='p'
              textStyle='body-2'
              color='neutral-800'
              textAlign={ articleTextAlign }
            >
              { titleSupport }
            </Text>
          </div>
          }
          { disclaimer &&
          <div className='Article__contentDisclaimer'>
            <Text
              htmlTag='p'
              textStyle='body-3'
              color='neutral-700'
              textAlign={ articleTextAlign }
            >
              { disclaimer }
            </Text>
          </div>
          }
          { valueTag &&
          <div className={
            classNames( 'Article__valuetag', {
              [`Article__valuetag--${articleTextAlign}`]:articleTextAlign
            } )
          }
          >
            <Tag
              messageText={ valueTag }
              messageType={ hasColorNoGray ? 'valuetagWhite' : 'valuetag' }
            />
          </div>
          }
          { linkAction && linkAction.label &&
          <div className={
            classNames( 'Article__contentLabel', {
              [`Article__contentLabel--${articleTextAlign}`]:articleTextAlign
            } )
          }
          >
            <Text htmlTag='span' >
              { linkAction.label }
            </Text>
          </div>
          }
        </div>
      </>
    );
  };

  return (
    <div className={
      classNames( 'Article', {
        [`Article__${ upCountText }UpContentImage--${ upCountCounter }`]: upCountText,
        [`Article__cardOrientation Article__cardOrientation--${cardOrientation}`]: cardOrientation,
        [`Article__cardBackgroundColor--${cardColor}`]: cardColor,
        'Article__contentTitle--noLink': articleNoLinkStyle
      } )
    }
    ref={ articleCardRef }
    >
      { articleLink &&
        <Link_Huge action={ linkAction } >
          { articleContent( ) }
        </Link_Huge>
      }
      { !articleLink &&
        <div className='Article__mainContainer'>
          { articleContent( ) }
        </div>
      }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef ArticleProps
 * @type {object}
 * @property {string} title - Sets the title properties
 * @property {string} titleSupport - Sets the title support properties
 * @property {string} subtitle Sets the subtitle support properties
 * @property {string} backgroundColor - Sets the backgroundColor
 * @property {string} patternBackground - Sets the patternBackground
 * @property {object} articles - Sets the article properties
 * @property {string} articleTextAlign - Sets the article text alignment
 * @property {string} textLayout - Sets the layout of the card text
 * @property {object} linkAction - Sets the linkAction
 * @property {string} cardOrientation - Sets whether it is a horizontal or vertical card
 * @property {boolean} horizontalFlipText - If true, it flips the text and image
 * @property {string} upCountText - Text for the number of Ups there are
 * @property {number} upCountCounter - The counter for each article
 */

export const propTypes = {
  title: PropTypes.string,
  titleSupport: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  patternBackground:  PropTypes.object,
  articles: PropTypes.array,
  articleTextAlign: PropTypes.string,
  textLayout: PropTypes.string,
  linkAction: PropTypes.object,
  cardOrientation: PropTypes.oneOf( ['horizontal', 'vertical'] ),
  horizontalFlipText: PropTypes.bool,
  upCountText: PropTypes.string,
  upCountCounter: PropTypes.number
};

/**
* Default values for passed properties
* @type object
*/
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS,
  tagDisplayType: 'contextual'
};

Article.defaultProps = defaultProps;
Article.propTypes = propTypes;

export default Article;